<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Vendor Invoice</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Vendor"
          name="Vendor"
          :value="supplier"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="PO Code"
          name="PO Code"
          :value="purchase.ReferencePurchaseCode"
          readonly
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Number"
          name="Invoice Number"
          :value="purchase.Code"
          readonly
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Due Date"
          name="Due Date"
          :value="dateFormat(purchase.DueDate)"
          readonly
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Payment Term"
          name="Payment Term"
          :value="purchase.PaymentTermName"
          readonly
        />
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Bank Name"
          name="Bank Name"
          :value="
            purchase.BankName +
            ' - ' +
            purchase.AccountNumber +
            ' ' +
            purchase.AccountName
          "
          readonly
        />
      </div>
    </div>
    <vs-divider style="width: 100%"><b>Notes</b></vs-divider>
    <template v-for="note in purchase.Notes.split(';')">
      <b v-bind:key="note"
        >{{ note }}
        <br />
      </b>
    </template>
    <br />
    <vs-divider style="width: 100%"><b>Comment</b></vs-divider>
    {{ purchase.CommentInvoice }}

    <vs-divider style="width: 100%"><b>Attachment</b></vs-divider>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            accept=".jpg, .jpeg, .png"
          />
        </div>
      </div>
    </div>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div v-if="canEdit" class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <vs-button class="mr-3 mb-2" @click="handleAttachment"
            >Add Attachment</vs-button
          >
        </div>
      </div>
    </div>
    <vs-divider style="width: 100%"><b>List Attachment</b></vs-divider>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%">
      <table class="vs-table vs-table--tbody-table">
        <!-- attachment -->
        <template v-for="(tr, i) in attachment">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.name"
          >
            <td class="td vs-table--td">{{ tr.NameFile }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.PathFile != '' && canView">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="downloadFileAwsS3(tr.path)"
                  icon="icon-eye"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template v-if="canEdit">
                <vx-tooltip text="Delete">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="handleDeleteAttachment(i, tr.ID)"
                    icon="icon-trash"
                  />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>

    <vs-divider style="width: 100%"></vs-divider>
    <span>
      <h4 class="mb-5">Line Item</h4>
    </span>
    <div class="vx-row mb-2">
      <table class="table">
        <thead style="font-weight: bold">
          <tr>
            <td>Item</td>
            <td>Qty Inv</td>
            <td>Total Price</td>
            <td>Discount</td>
            <td>Net</td>
            <td>Tax</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in lineItem">
            <tr v-bind:key="item.ID">
              <td>{{ item.ItemName }}</td>
              <td>{{ item.Quantity }} {{ item.ItemUnit }}</td>
              <td>{{ priceFormat(item.Price) }}</td>
              <td>{{ priceFormat(item.Discount) }}</td>
              <td>{{ priceFormat(item.Subtotal) }}</td>
              <td>{{ priceFormat(item.Tax) }} ({{ item.TaxRate }}%)</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <vs-divider style="width: 100%"></vs-divider>
    <span>
      <h4 class="mb-5">Charges</h4>
    </span>
    <div class="vx-row mb-2">
      <table class="table">
        <thead style="font-weight: bold">
          <tr>
            <td>Charge Name</td>
            <td>Value</td>
            <td>Tax</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in charge">
            <tr v-bind:key="item.ID">
              <td>{{ item.ChargeName }}</td>
              <td>{{ priceFormat(item.Amount) }}</td>
              <td>{{ priceFormat(item.TaxAmount) }} ({{ item.TaxRate }}%)</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <vs-divider style="width: 100%"></vs-divider>
    <span>
      <h4 class="mb-5">Propose Payment</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Payment Value"
          name="Payment Value"
          :value="priceFormat(propose.PaidValue)"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <vs-alert
        color="warning"
        class="mt-3"
        icon-pack="feather"
        close-icon="icon-x"
      >
        Propose Payment of <b>{{ priceFormat(propose.PaidValue) }}</b> for
        <b>{{ purchase.Code }}</b> has been approved
      </vs-alert>
    </div>
    <!-- confirmation modal -->
    <vs-prompt
      title="Confirmation"
      color="primary"
      @accept="doSubmit"
      @cancel="closeConfirmation"
      @close="closeConfirmation"
      :buttons-hidden="false"
      :active.sync="confirmation"
    >
      <div class="con-exemple-prompt">
        Proposal Payment Need Approval Propose Payment of
        <b>{{ priceFormat(propose.PaidValue) }}</b> for
        <b>{{ purchase.Code }}</b>
        <br />
        Are you sure to
        <b>{{ approveOrReject ? "Approve" : "Reject" }}</b> Propose Payment ?
        <br />
        <br />
        <vs-textarea v-model="notes" placeholder="Note" />
      </div>
    </vs-prompt>
    <!-- <div class="vx-row mb-2">
       <div class="vx-col" style="margin-bottom: 150px; margin-top: 50px">
          <vs-button
            v-on:click="doApprove"   
            color="primary"
            icon-pack="feather"
            icon="icon-inbox"
            >Approve</vs-button
          >
        </div>
        <div class="vx-col" style="margin-bottom: 150px; margin-top: 50px">
          <vs-button
            v-on:click="doReject"   
            color="warning"
            icon-pack="feather"
            icon="icon-x"
            >Reject</vs-button
          >
        </div>
    </div> -->
    <!-- <vs-divider style="width: 100%;"></vs-divider>
    <span>
      <h4 class="mb-5">Line Item</h4>
    </span>
    <div class="vx-row mb-2">
    </div> -->
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    selected: Object,
  },
  mounted() {
    console.log(this.selected.id, "data");
    this.getData();
  },
  data() {
    return {
      purchase: {},
      lineItem: [],
      charge: [],
      attachment: [],
      propose: {},
      notes: "",
      confirmation: false,
      approveOrReject: 0,
      file: null,
    };
  },
  computed: {
    supplier() {
      return this.purchase.SupplierCode + " " + this.purchase.SupplierName;
    },
    canEdit() {
      return this.$store.getters["user/hasPermissions"]("edit");
    },
    canView() {
      return this.$store.getters["user/hasPermissions"]("view");
    },
  },
  watch: {
    "selected.ID"() {
      this.getData();
    },
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("YYYY-MM-DD");
      }
    },
    handleShowAttachment(selected) {
      if (selected.path != "" && selected.path !== undefined) {
        window.open(selected.path);
      } else if (selected.PathFile != "" && selected.PathFile !== undefined) {
        window.open(selected.PathFile);
      }
    },
    handleDeleteAttachment(index, id) {
      this.deleteFile(id);
      this.attachment = this.attachment.filter((item, i) => i != index);
      // console.log(this.table.file);
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/pi/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    doApprove() {
      this.approveOrReject = 1;
      this.notes = "";
      this.confirmation = true;
    },
    doReject() {
      this.approveOrReject = 0;
      this.notes = "";
      this.confirmation = true;
    },
    closeConfirmation() {
      this.confirmation = false;
    },
    doSubmit() {
      this.$vs.loading();
      const params = {
        status: this.approveOrReject,
        propose_id: this.propose.ID,
        purchase_invoice_id: this.propose.PurchaseInvoiceID,
      };

      this.$http
        .post("/api/v1/purchase-payment-proposal/change-status", params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    handleAttachment() {
      console.log(this.$refs.file.files[0]);
      if (this.$refs.file.files[0]) {
        // this.table.file.push({
        //   name: this.$refs.file.files[0].name,
        //   file: this.$refs.file.files[0],
        //   isNew: true,
        //   path: "",
        // });
        this.file = this.$refs.file.files[0];
        document.querySelector("#fileInput").value = "";
        this.uploadData();
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    paramUpload() {
      var form = new FormData();
      form.append("pi_id", this.propose.PurchaseInvoiceID);
      form.append("file", this.file);
      form.append("type", this.file.type);
      return form;
    },
    uploadData() {
      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/upload-file", this.paramUpload(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.attachment.push({
            ID: resp.data.attachment.ID,
            NameFile: resp.data.attachment.NameFile,
            PathFile: resp.data.attachment.PathFile,
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getData() {
      this.$http
        .get("/api/v1/purchase-payment-proposal/line/" + this.selected.ID)
        .then((r) => {
          this.purchase = r.data.purchaseInvoice;
          this.lineItem = r.data.linePurchaseInvoice;
          this.charge = r.data.charge;
          this.propose = r.data.purchasePaymentProposal;
          this.attachment = r.data.attachment;
          console.log(r.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
